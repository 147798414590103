import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Hero from "../components/hero"
import NewsGrid from "../components/news-grid"
import FeaturedArticle from "../components/featured-article"
import CtaSlider from "../components/cta-slider"

export const query = graphql`
  query Category($slug: String!) {
    articles: allStrapiArticle(
      filter: { category: { slug: { eq: $slug } } }
      sort: { order: DESC, fields: updatedAt }
    ) {
      nodes {
        ...StrapiArticles
      }
    }
    category: strapiCategory(slug: { eq: $slug }) {
      name
      slug
      description
      news_advert_overlays {
        title
        heading
        description
        buttonName
        link
        id
      }
    }
    strapiNews {
      hero {
        ...StrapiHero
      }
      featuredArticle {
        ...StrapiFeaturedArticle
      }
      callToAction {
        ...StrapiCallToAction
      }
      seo {
        ...StrapiSeo
      }
    }   
    allStrapiNewsAdvertOverlay {
      nodes {
        title
        heading
        description
        buttonName
        link
        id
        isInternal
        image {
          alternativeText
          localFile {
            url
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                layout: CONSTRAINED
                placeholder: BLURRED
                aspectRatio: 1.1
              )
            }
          }
        }
      }
    }
  }
`

const Category = ({ data }) => {
  //console.log("Category - Data: ", data)

  const seo = data.strapiNews.seo
  const structuredData = seo.structuredData
  //console.log("Category - Data - SEO: ", seo)

  const articles = data.articles.nodes
  const categoryName = data.category.description
  const category = data.category
  //console.log("Data - Category: ", category)
  const hero = data.strapiNews.hero
  const featuredArticle = data.strapiNews.featuredArticle

  const callToAction = data.strapiNews.callToAction
  const callToActionSections = data.strapiNews.callToAction.call_to_action_sections

  const adverts = []

  category.news_advert_overlays.map(news_advert_overlay =>
    //console.log("news_advert_overlay: ", news_advert_overlay)

    data.allStrapiNewsAdvertOverlay.nodes.map(ad => {
      //console.log("news_advert_overlay - ad: ", ad)
      
      if (news_advert_overlay.title === ad.title) {
        adverts.push({ ...ad })
     }
     return ad
    })
  )

  

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={`${category.name} category`}
        description={`All ${category.description} articles`}
        image={seo.metaImage.localFile.publicURL}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <FeaturedArticle 
        featuredArticle={featuredArticle}
        title={categoryName + " " + "Category"}
        description={`Welcome to our ${categoryName}, see our articles and blogs as well as our new initiatives taking place!`}
        bg="uk-background-default"
      />

      <NewsGrid
        articles={articles}
        category={categoryName}
        categoryAdverts={adverts}
      />

      <CtaSlider 
        title={callToAction.title} 
        description={callToAction.description} 
        callToActionSections={callToActionSections} 
        bg="uk-background-muted"
      />
    </>
  )
}

export default Category
